import React, { useState, useEffect } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';

import AWS from 'aws-sdk';

import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';


AWS.config.update({
    region: 'eu-west-2'
  })

function DelistingDashboard() {

    const [dashURL, setDash] = useState(null)
    const getUserAttributes = async ({ userAttributes }) => {
        // Note that return type has changed from array to object
        const attributes = await fetchUserAttributes();
      }


    


    const getSession = async () => {

            const {
            tokens,
            credentials,
            identityId,
            userSub
            } = await fetchAuthSession();
        
            const {
            accessKeyId,
            sessionToken,
            secretAccessKey,
            expiration
            } = credentials;

            const payload = {username : userSub}

            var params = {
            FunctionName: 'bpi-cpu-member-portal-generate-metabase-url', /* required */
            InvocationType: 'RequestResponse',
            Payload: JSON.stringify(payload),
    
            };
        
            const lambda = new AWS.Lambda({
            credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey,
                sessionToken: sessionToken,
                expiration: expiration
            }
            });
        
            lambda.invoke(params, (err, data) => {
            if (err) {
                console.log(err, err.stack); // an error occurred
            } else {
                const response = JSON.parse(data['Payload']).body;
                setDash(response)}})

            }

        useEffect(() => {
            getSession();
        }, []);



               

return(
    dashURL ? 
    <div style={{padding:'5%'}}>
        
        <iframe
            src={dashURL}
            frameborder="0"
            width="1200"
            height="1800"
        ></iframe>
</div> : <div style={{padding:'10%'}}><CircularProgress /><br></br><Typography>Just a moment, we are loading your delisting dashboard...</Typography></div>
)

    
}

export default DelistingDashboard;