import './App.css'
import { Route, Routes } from "react-router-dom"

import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';

import Home from './pages/home.jsx';
import Navbar from './components/navbar.jsx';

import { createTheme, ThemeProvider } from '@mui/material';

Amplify.configure(awsExports);


const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#0d1a26',
    },
  },
  typography: {
      button: {
          textTransform: 'none'
        },
      fontFamily: 'Jost',
        h2:{ fontSize: '3.25rem'},
        h4:{ fontSize: [60, "!important"]},
        body1: {
          fontSize: [24, "!important"]
      },
      body2: {
          fontSize: [20, "!important"]
      }}
});

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default withAuthenticator(App, {hideSignUp: true});
