import React, { useState, useEffect } from 'react';
import DelistingDashboard from '../components/delisting-dashboard';



function Home() {
    
    return(
    <div>
        <DelistingDashboard />
    </div>
    )
}

export default Home;