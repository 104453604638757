import React from "react";

import { Button } from '@mui/material';
import { signOut } from 'aws-amplify/auth';

import {
  AppBar,
  Grid,
  Toolbar,
  CssBaseline,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material';


const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#0d1a26',
    },
  },
  typography: {
      button: {
          textTransform: 'none'
        },
      fontFamily: 'Jost',
        h2:{ fontSize: '3.25rem'},
        h4:{ fontSize: [60, "!important"]},
        body1: {
          fontSize: [24, "!important"]
      },
      body2: {
          fontSize: [20, "!important"]
      }}
});

function Navbar() {
  
    async function handleSignOut() {
        try {
            await signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
        }
        
  return (
    <ThemeProvider theme={theme}>
    <AppBar position="static">
      <CssBaseline />
      <Toolbar>
        <Grid container>
            <Button variant="contained" onClick={handleSignOut}>Sign Out</Button>
        </Grid>
            
      </Toolbar>
    </AppBar>
    </ThemeProvider>
  );
}
export default Navbar;